import axios from 'axios'

class ContactService {

    PostEmail = async (data) => {

        //console.log(data)

        return await axios.post("/sand/contact.php", data)
        .then((response) => {
            return response.data
        }).catch((error) => {
          console.log(error);
          return null
        });
    }

    CheckreCAPTCHA  = async (token) => {
        return await axios.post("/sand/reCAPTCHA.php", token)
        .then((response) => {
            return response.data
        }).catch((error) => {
          console.log(error);
          return null
        });
    }

}

export default ContactService;