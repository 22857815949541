import React from 'react'

import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const EPayInfo = [
    {
        title: '完整金流功能',
        subTitle: '無須建立商品資料・ 3秒完成建立・10秒完成付款',
        feature: ["支援Visa、Master Card、JCB、銀聯卡","支援Apple Pay、Google Pay","支援國內及國外信用卡3D安全驗證","虛擬帳號數位收款最安心"],
        img: './images/finance/EPay/EPay_01.png',
        order1: { xs: 1, md: 1 },
        order2: { xs: 2, md: 2 },
        justifyContent: "flex-start"
    },{
        title: '數位收銀',
        subTitle: '快速支付無負擔，多元收款不受限',
        feature: ["即點即付，免下載APP、免註冊會員、免裝卡機","付款不受限：實體門市或線上客戶都能支付","多元支付方式：線上信用卡付款、虛擬帳號","收款不用綁定電商購物系統！"],
        img: './images/finance/EPay/EPay_02.png',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end"
    },{
        title: '訂單管理',
        subTitle: '精準掌握，高效管理',
        feature: ["快速查詢消費者訂單明細","訂單付款狀態","詳細收貨人與收貨地址物流出貨"],
        img: './images/finance/EPay/EPay_03.png',
        order1: { xs: 1, md: 1 },
        order2: { xs: 2, md: 2 },
        justifyContent: "flex-start"
    },{
        title: '會員載具',
        subTitle: '購物快速、輕鬆保存',
        feature: ["會員載具平台","電子郵件寄送發票","雲端儲存電子發票","消費者可線上查詢發票明細"],
        img: './images/finance/EPay/EPay_04.png',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end"
    }
]

function FinanceEPay() {
    return (
        <>
            <Box className="mainBanner" sx={{ backgroundImage: 'url(./images/finance/EPay/mainBanner.jpg)', justifyContent:"flex-start" }}>
                <Container maxWidth="md" sx={{ textAlign: "left" }}>
                    <Box sx={{ width:"fit-content", mb:"20px" }}>
                        <Typography variant="h6">Financial Technology</Typography>
                        <Typography variant="h4" component="h1" sx={{mt:"10px", pb: "20px", borderBottom: "2px solid #FFF"}}>數位支付</Typography>
                    </Box>
                    <Typography variant="p" sx={{lineHeight:"1.5em"}}>提供全方位的金流與支付整合服務<br/>創新科技應用、提升風險控管能力、優化使用者體驗</Typography>
                </Container>
            </Box>
            {
                EPayInfo.map((item, index)=>{
                    return (
                        <Box className="indexSection" key={index} sx={{background: item.bg === "blue" ? "#212437" : ""}}>
                            <Grid container spacing={0} sx={{width:"100%"}}>
                                <Grid xs={11} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center" order={item.order1}>
                                    <img src={item.img} alt={item.title} width="100%" />
                                </Grid>
                                <Grid xs={11} md={6} display="flex" justifyContent={item.justifyContent} alignItems="center" order={item.order2}>
                                    <Box sx={{maxWidth: {xs:"100%",md: "70%"}, textAlign: 'left', p:"5%"}}>
                                    <Typography variant="body1" color="white" sx={{mb:1, color: "#cd6f00"}}>{item.subTitle}</Typography>
                                        <Typography gutterBottom variant="h4" color="white">{item.title}</Typography>
                                        <List
                                            sx={{ width: '100%' }}
                                            component="div"
                                            aria-labelledby="nested-list-subheader"
                                        >
                                            {
                                                item.feature.map((list, index)=>{
                                                    return(
                                                        <ListItem key={index}>
                                                            <CheckCircleIcon sx={{color: "#cd6f00"}} />
                                                            <Typography variant="body1" sx={{ml:1}} color="white">{list}</Typography>
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )
                })
            }
        </>
    );
}

export default FinanceEPay;