import React from 'react'

import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

const introduceInfo = [
    {
        title: '通路顧問',
        subTitle: '從銷售數字到品牌佔比，掌握市場的反應與數據。',
        contentTxt: '競品資訊追蹤與分析，替客戶規劃產品教育與品牌的定位教育，讓門市人員能夠與消費者有良好的互動，並從門市的互動傳遞活動訊息，也在銷售中收集顧客反應的數據。'
    }, {
        title: '整合營銷',
        subTitle: '專業策略、社群運營、實體活動，提升品牌曝光與聲量，深植人心，助力營收成長。',
        contentTxt: '為您的產品提供全方位行銷解決方案。根據產品定位，客製化平面宣傳，進行網路廣告和社群操作，結合網紅影音，最大化資源效益，提升知名度。同時籌辦各類活動，包括通路門市活動、經銷商研討會以及新品發表會，吸引人潮，同時深化品牌印象，加深消費者對產品的了解與信任。'
    }, {
        title: '數位金融',
        subTitle: '整合創新科技，為您的產品打造獨特營銷方案。',
        contentTxt: '我們提供全方位的數位金融服務，包括電子發票、數位支付，以及創新的LINE好友社群商城和LINE應用線上點單。透過客製化平面宣傳，網路廣告和LINE社群操作，最大程度地發揮數位金融支付，提升品牌知名度。'
    }
]

const TimeLineInfo = [
    {
        title: "消費者為起點核心",
        subTitle: ""
    },{
        title: "了解競品資訊追蹤與數據分析",
        subTitle: "通路市場鋪貨數、缺貨分析、數據比對、競品鋪貨率"
    },{
        title: "規劃產品教育與品牌的定位教育",
        subTitle: "品牌、商務企業、服務提供者，會員經營"
    },{
        title: "通路經營，維繫客戶與通路之間的關係",
        subTitle: "連鎖品牌、量販店以及傳統零售通路"
    },{
        title: "客製化，依產品定位訴求",
        subTitle: "規劃社群操作、網路廣告投放"
    },{
        title: "全省商圈活動規劃",
        subTitle: "門市活動、研討會、新品發表會、戶外大型活動"
    }
]

const performanceInfo = ['3,000+ 實體門市', '電子商務', '收款工具', '加值服務']

function AboutPage() {

    const filenames = [];

    Array.from({ length: 22 }).forEach((_, i) => {
        filenames.push(`guest_${(i + 1).toString().padStart(2, '0')}`);
    });

    return (
        <>
            <Box className="mainBanner" sx={{ backgroundImage: 'url(./images/about/mainBanner.jpg)', justifyContent:"flex-start" }}>
                <Container maxWidth="md" sx={{ textAlign: "left" }}>
                    <Box sx={{ width:"fit-content", mb:"20px" }}>
                        <Typography variant="h6">About Us</Typography>
                        <Typography variant="h4" component="h1" sx={{mt:"10px", pb: "20px", borderBottom: "2px solid #FFF"}}>關於聖洺</Typography>
                    </Box>
                    <Typography variant="p" sx={{lineHeight:"1.5em"}}>協助企業於通路領域<br/>創建數位金融生態服務為核心的通路顧問</Typography>
                </Container>
            </Box>
            <Container maxWidth="lg" sx={{ display: "flex", flexWrap: "wrap", textAlign: "left", justifyContent: "center", py: "40px" }}>
                <Box sx={{ width:"fit-content", mb:"20px", color: "#FFF", lineHeight: "2em" }}>
                    <Typography variant="body1">客戶通路公關行銷、數位營銷及顧問服務</Typography>
                    <Typography variant="h4" component="h2">3C科技、金融數位、食品茶餐飲<br/>商圈零售整合、公部門跨業結合專案</Typography>
                </Box>
                <Box sx={{ width:"100%", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {
                        introduceInfo.map((item, index)=>{
                            return(
                                <Card key={index} className='PlanCard' sx={{borderLeft: "1px solid #ff853c"}}>
                                    <CardContent sx={{px: 3, textAlign: "left"}}>
                                        <Typography variant="h5" sx={{color:"#ff853c"}}>{item.title}</Typography>
                                        <Typography variant="body2" sx={{color:"#ff853c",mb:2}}>{item.subTitle}</Typography>
                                        <Typography variant="body2" color="text.secondary">{item.contentTxt}</Typography>
                                    </CardContent>
                                </Card>
                            )
                        })
                    }
                </Box>
            </Container>
            <Box className="indexSection ColorBG_W">
                <Grid container spacing={2} sx={{width:"100%"}}>
                    <Grid xs={11} md={6} display="flex" justifyContent="center" alignItems="center" sx={{py: {xs:5, md:10}}}>
                        <img src="images/about/about_01.jpg" alt="消費者行為分析" width="100%" />
                    </Grid>
                    <Grid xs={11} md={6} display="flex" justifyContent="flex-start" alignItems="center">
                        <Box sx={{width:{xs:"100%", md: "80%"}}}>
                            <Timeline
                                    sx={{
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0.1,
                                            padding: "8px",
                                        },
                                    }}
                                >
                                {
                                    TimeLineInfo.map((item, index)=>{
                                        return(
                                            <TimelineItem key={index}>
                                                <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Typography variant="h5" component="h2" sx={{color:{xs:"#FFF",md:"#000"},fontWeight:"bold"}}>{item.title}</Typography>
                                                    <Typography variant="caption" component="p" sx={{color:"#ff853c"}}>{item.subTitle}</Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        )
                                    })
                                }
                            </Timeline>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Container maxWidth="lg" sx={{ display: "flex", flexWrap: "wrap", textAlign: "center", justifyContent: "center", py: "60px" }}>
                <Box sx={{ width:"fit-content", mb:5, color: "#FFF", lineHeight: "2em" }}>
                    <Typography variant="h4" component="h2">從光華橋拆遷至今走過18年</Typography>
                    <Typography variant="h4" component="h2">市場通路經驗最豐富的專業顧問公司</Typography>
                </Box>
                <Box sx={{ width:"100%", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {
                        performanceInfo.map((item, index)=>{
                            return(
                                <Card key={index} className='FinTechCard' sx={{width:"100%", display: "flex", justifyContent: "center", background:"#cd6f00"}}>
                                    <CardContent>
                                        <Typography variant="h6" sx={{mt:1,mb:0.3}}>{item}</Typography>
                                    </CardContent>
                                </Card>
                            )
                        })
                    }
                </Box>
                <Box sx={{ width:"fit-content", my:5, color: "#FFF", lineHeight: "2em" }}>
                    <Typography variant="h6" sx={{lineHeight:"1.5em", fontSize:"1.8rem"}}>
                        展場活動累積超過 <Typography variant='h5' component="span" sx={{fontWeight:"bold", color:"#cd6f00", fontSize:"2.5rem"}}>500</Typography> 場，累積品牌客戶創造 <Typography variant='h5' component="span" sx={{fontWeight:"bold", color:"#cd6f00", fontSize:"2.5rem"}}>30億</Typography> 營收
                    </Typography>
                </Box>
                <List sx={{width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                    {
                        filenames.map((item, index)=>{
                            return(
                                <ListItem key={index} sx={{flex: 0,m:"10px 3px"}}>
                                    <img src={`./images/index/guest/${item}.png`} alt={item} style={{maxWidth:"120px", maxHeight:"40px"}} />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Container>
        </>
    );
}

export default AboutPage;