import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from "@mui/material/styles";

import NavContext from '../contexts/navContext';

const drawerWidth = 260;

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    backgroundColor: "transparent"
}));

const pageList = [
    {name: "整合行銷", url:"/marketing"},
    {name: "數位金融", url:"/finance"},
    {name: "數位媒體", url:"/media"},
    {name: "精彩案例", url:"/case"},
    {name: "關於聖洺", url:"/about"},
    {name: "聯絡我們", url:"/contact"}
];

const MenuDrawer = ({handleDrawerClose}) => {

    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useContext(NavContext);

    return (
        <Drawer
            sx={{
            width: `${drawerWidth}px`,
            flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: `${drawerWidth}px`,
                    boxSizing: "border-box",
                },
            }}
            variant="temporary"
            anchor="right"
            open={menuOpen}
            onClose={handleDrawerClose}
        >
            <List
                sx={{ width: '100%' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {
                    pageList.map((page) => (
                        <ListItem key={page.name}>
                            <ListItemButton onClick={()=>{setMenuOpen(false);navigate(page.url)}} style={{color:"#FFF", textDecoration: "none"}}>
                                {page.name}
                            </ListItemButton>
                        </ListItem>
                    ))
                }
            </List>
        </Drawer>
    )

}

export default MenuDrawer