import React from 'react'
import {useNavigate} from 'react-router-dom';

import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const PlanInfo = [
    {
        title: '整合營銷',
        subTitle: 'Integrated Marketing',
        contentTxt: '通路活動、品牌行銷、數據分析、設計視覺、展演活動、銷售工具。',
        img: '/images/index/planPic_01.jpg',
        url: '/marketing'
    }, {
        title: '數位金融',
        subTitle: 'FinTech',
        contentTxt: '全方位數位金融服務、電子發票、數位支付、LINE好友社群商城、LINE應用線上點單。',
        img: '/images/index/planPic_02.jpg',
        url: '/finance'
    }, {
        title: '數位媒體',
        subTitle: 'Digital Media',
        contentTxt: 'LINE行銷、網紅口碑、社群口碑、論壇/部落客操作，YouTube自媒體。',
        img: '/images/index/planPic_03.jpg',
        url: '/media'
    }
]

const HomePage = () => {

    const navigate = useNavigate();
    const filenames = [];

    Array.from({ length: 22 }).forEach((_, i) => {
        filenames.push(`guest_${(i + 1).toString().padStart(2, '0')}`);
    });

    return (
        <>
            <Box className="mainBanner" sx={{ backgroundImage: 'url(/images/index/mainBanner.jpg)', justifyContent:"flex-start" }}>
                <Container maxWidth="md" sx={{ textAlign: "left" }}>
                    <Box sx={{ width:"fit-content", mb:"20px" }}>
                        <Typography variant="h6">Channel Marketing Consultant</Typography>
                        <Typography variant="h4" component="h1" sx={{mt:"10px", pb: "20px", borderBottom: "2px solid #FFF"}}>零售通路最佳解決方案</Typography>
                    </Box>
                    <Typography variant="p" sx={{lineHeight:"1.5em"}}>為您量身訂做最適合的營銷策略<br />從品牌規畫到市場執行、垂直式的策略服務</Typography>
                </Container>
            </Box>
            <Box>
                <Container maxWidth="lg" sx={{ display: "flex", flexWrap: "wrap", textAlign: "left", justifyContent: "center", py: "60px" }}>
                    <Box sx={{ width:"fit-content", mb:"20px", color: "#FFF", lineHeight: "2em" }}>
                        <Typography variant="body1">聖洺通路顧問給您</Typography>
                        <Typography variant="h4" component="h2">最佳的零售通路整合方案</Typography>
                    </Box>
                    <Box sx={{ width:"100%", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                        {
                            PlanInfo.map((item, index)=>{
                                return(
                                    <Card key={index} className='PlanCard' sx={{ backgroundImage: `url(${item.img})`,aspectRatio: "1 / 1" }}>
                                        <CardContent sx={{px: 8}}>
                                            <Typography variant="body2" color="text.secondary">{item.subTitle}</Typography>
                                            <Typography gutterBottom variant="h5">{item.title}</Typography>
                                            <Typography variant="body2" color="text.secondary">{item.contentTxt}</Typography>
                                            <Button size="medium" variant="outlined" color="inherit" sx={{mt: 3}} onClick={()=>{navigate(item.url)}}>了解更多</Button>
                                        </CardContent>
                                    </Card>
                                )
                            })
                        }
                    </Box>
                </Container>
            </Box>
            <Box className="indexSection ColorBG">
                <Grid container spacing={2} sx={{width:"100%"}}>
                    <Grid xs={11} md={6} display="flex" justifyContent="center" alignItems="center" sx={{py: {xs:5, md:10}}}>
                        <img src="/images/index/Section2_Pic.jpg" alt="消費者行為分析" width="100%" />
                    </Grid>
                    <Grid xs={11} md={4} display="flex" justifyContent="flex-start" alignItems="center">
                        <Box sx={{maxWidth: "80%", textAlign: 'left', p:"5%"}}>
                            <Typography variant="body1" color="text.secondary" sx={{mb:1}}>消費者為起點核心</Typography>
                            <Typography gutterBottom variant="h4" color="white">分析消費者行為<br/>探索分析消費動機目的</Typography>
                            <Typography variant="body1" color="text.secondary">我們「以自動化的執行流程」進一步打通線上線下資料，達成維繫舊客、開發新客的目標，系統自動準確掌握消費者的足跡，再透過AI分群分眾，協助品牌全面瞭解顧客行為輪廓。</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="indexSection">
                <Grid container spacing={2} sx={{width:"100%"}} display="flex" justifyContent="center" alignItems="center">
                    <Grid xs={11} md={4} display="flex" justifyContent="center" alignItems="center" order={{ xs: 2, md: 1 }}>
                        <Box sx={{maxWidth: "80%", textAlign: 'left', p:"5%"}}>
                            <Typography variant="body1" color="text.secondary" sx={{mb:1}}>「市場」「產業」趨勢發展解析</Typography>
                            <Typography gutterBottom variant="h4" color="white">市場結構<br/>規劃經營策略</Typography>
                            <Typography variant="body1" color="text.secondary">以探討市場與產業重要趨勢、原廠營運策略等分析重點，貫穿產業上中下游與終端市場，提供專業的產業報告服務，全方位掌握市場最新情況、研判趨勢與關鍵資訊，為經營布局決策參考。</Typography>
                        </Box>
                    </Grid>
                    <Grid xs={11} md={6} display="flex" justifyContent="center" alignItems="center" sx={{py: 10}} order={{ xs: 1, md: 2 }}>
                        <img src="/images/index/Section3_Pic.jpg" alt="市場結構規劃經營策略" width="100%" />
                    </Grid>
                </Grid>
            </Box>
            <Box className="indexSection ColorBG">
                <Grid container spacing={2} sx={{width:"100%"}}>
                    <Grid xs={11} md={6} display="flex" justifyContent="center" alignItems="center" sx={{py: 10}}>
                        <img src="/images/index/Section4_Pic.jpg" alt="全方位整合應用方案" width="100%" />
                    </Grid>
                    <Grid xs={11} md={4} display="flex" justifyContent="flex-start" alignItems="center">
                        <Box sx={{maxWidth: "80%", textAlign: 'left', p:"5%"}}>
                            <Typography variant="body1" color="text.secondary" sx={{mb:1}}>提供全方位整合應用方案</Typography>
                            <Typography gutterBottom variant="h4" color="white">通路顧問、整合營銷服務、數位金融產品</Typography>
                            <Typography variant="body1" color="text.secondary">致力於協助客戶在網路通路、實體通路、物流通路、未知通路等開發，提供通路關係策略經營、通路行銷、通路品牌經營、整合行銷等，提供完整創新的品牌通路策略經營與完美服務的品牌合作夥伴使企業於通路領域開闢新藍海。</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Container maxWidth="lg" sx={{ display: "flex", flexWrap: "wrap", textAlign: "left", justifyContent: "center", py: "60px" }}>
                <Box sx={{ width:"fit-content", mb:"20px", color: "#FFF", lineHeight: "2em" }}>
                    <Typography variant="h4" component="h2">我們的客戶</Typography>
                </Box>
                <List sx={{width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                    {
                        filenames.map((item, index)=>{
                            return(
                                <ListItem key={index} sx={{flex: 0,m:"10px 3px"}}>
                                    <img src={`/images/index/guest/${item}.png`} alt={item} style={{maxWidth:"120px", maxHeight:"40px"}} />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Container>
        </>
    )
}

export default HomePage;