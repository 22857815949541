import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import MenuDrawer from "./Drawer";
import NavItems from './navItem'

import MuiAppBar from '@mui/material/AppBar';
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from '@mui/material/Container';

import NavContext from '../contexts/navContext';
import CustomerService from "../service/customerService";

const LogoUrl = "./images/common/SaintMen_LOGO_W.svg"
const drawerWidth = 260;

const customerService = new CustomerService()

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    ...(open && {
        left: `${drawerWidth}px`,
        transition: "all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms"
    }),
}));

const Nav = () => {

    const [menuOpen, setMenuOpen] = useContext(NavContext)

    const handleDrawerOpen = () => {
        setMenuOpen(true);
    };
    const handleDrawerClose = () => {
        setMenuOpen(false);
    };

    return (
        <React.Fragment>
            <AppBar
                position="fixed"
                open={menuOpen}
                sx={{
                    background: 'linear-gradient(to bottom, rgba(0,0,0,1) 30%,rgba(0,0,0,0) 100%)',
                    boxShadow: 'none',
                    p: '10px'
                }}
            >
                <Container maxWidth="lg">
                    <Toolbar disableGutters>



                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="div" sx={{ my: 2 }} noWrap>
                                <Link to="/" reloadDocument><img src={LogoUrl} alt="LOGO" width={100} /></Link>
                            </Typography>
                        </Box>

                        <Box sx={{ display: { xs: 'none', md: 'block' } }} className="NavBtns">
                            <NavItems />
                        </Box>

                        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <IconButton
                                size="medium"
                                aria-label="Main Menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleDrawerOpen}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>

                    </Toolbar>
                </Container>
            </AppBar>

            <MenuDrawer handleDrawerClose={handleDrawerClose} />

        </React.Fragment>
    )
}

export default Nav;