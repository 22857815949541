import React from 'react'

import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

const evaluateInfo = [
    {
        title: '群眾口碑',
        contentTxt: '募集大量部落客 / Facebook用戶，體驗產品並撰文推薦給親朋好友，創造最真實且具說服力的大量文章，使品牌故事/產品優勢深入消費者心中!'
    }, {
        title: 'SEO優化',
        contentTxt: '透過 Blog 口碑文，置入預設產品關鍵字，優化Yahoo、Google 等搜尋引擎的自然排序結果，不只產生文章內容、也讓網友更容易找到與您相關的好口碑!'
    }, {
        title: '最佳見證內容',
        contentTxt: '部落客 / Facebook 用戶產生的大量文章，可精選引用作為網站或活動頁的最佳第三方見證，並於網路上持續曝光達成長尾行銷效益。'
    }
]

const TimeLineInfo = [
    {
        title: "LINE Messenger",
        subTitle: "一對一拉近人與人、人與資訊及應用服務間的距離"
    },{
        title: "LINE VOOM",
        subTitle: "短影音 Video、文章分享"
    },{
        title: "LINE​官方帳號",
        subTitle: "品牌、商務企業、服務提供者，會員經營"
    },{
        title: "LINE​社群",
        subTitle: "匿名討論社群"
    },{
        title: "LINE Today",
        subTitle: "新聞媒體"
    },{
        title: "LINE 熱點",
        subTitle: "零售實體門市 Map Store"
    }
]

const communityStep = [
    {
        title: '快速擴散',
        SubTitle: '社群聲量',
        contentTxt: '最短時間將口碑訊息以各種形式分享給眾多粉絲，並藉由與粉絲的互動過程，進一步觸及粉絲的好友圈，達成更大規模的擴散'
    }, {
        title: '提高曝光',
        SubTitle: 'Facebook名人',
        contentTxt: 'Facebook在台普及率高，眾多名人加持，可合作對象包含KOL、藝人歌手、特殊職業身分從業人員…等身分，讓品牌與產品的曝光更多元，吸引更多不同粉絲注目。'
    }, {
        title: 'SEO排名',
        SubTitle: '溝通無限',
        contentTxt: '達人部落客擁有高人氣、頻繁更新率、眾多精準搜尋指標等特性，讓產品口碑文章達成較好的搜尋結果優化，容易在搜尋引擎找到相關口碑推薦痕跡。'
    }
]

const KOLStep = [
    {
        title: 'KOL超專業推薦',
        contentTxt: '意見領袖往往比藝人巨星更擅長與粉絲互動，其專業體驗文更可拉近品牌與消費者距離，並藉由真實體驗情境內容，提升消費者對產品的信任感，進而產生購買慾望。'
    }, {
        title: '名人影響力',
        contentTxt: '除了意見領袖的高人氣瀏覽量及知名度可促進直接粉絲的購買慾，專業的口碑分享文更可成為消費者在購買行為前口碑參考的判斷指標！'
    }, {
        title: 'SEO排名',
        contentTxt: '達人部落客擁有高人氣、頻繁更新率、眾多精準搜尋指標等特性，讓產品口碑文章達成較好的搜尋結果優化，容易在搜尋引擎找到相關口碑推薦痕跡。'
    }
]

const ytStep = [
    {
        title: '生動真實',
        SubTitle: '見證體驗',
        contentTxt: '透過影音方式進行，在鏡頭前生動介紹、甚至直接示範，比起靜態圖文更讓人願意停下腳步見證實際體驗的過程、說服力大加分。'
    }, {
        title: '提高曝光',
        SubTitle: '讓世界看見',
        contentTxt: 'Youtube 在世界各國皆有眾多 KOL，只要標題與介紹內文設定得宜，除了台灣、更能讓世界各國網友都看見您的口碑影片。'
    }, {
        title: '全新思維',
        SubTitle: '流行國界',
        contentTxt: '只能透過台灣 KOL 來介紹商品？若能讓您的商品從國外暴紅回台灣，也是一種口碑的見證方式！跨國平台讓您有全新的國際思維。'
    }
]

function MediaPage() {
    return (
        <>
            <Box className="mainBanner" sx={{ backgroundImage: 'url(./images/media/mainBanner.jpg)', justifyContent:"flex-start" }}>
                <Container maxWidth="md" sx={{ textAlign: "left" }}>
                    <Box sx={{ width:"fit-content", mb:"20px" }}>
                        <Typography variant="h6">Digital Media</Typography>
                        <Typography variant="h4" component="h1" sx={{mt:"10px", pb: "20px", borderBottom: "2px solid #FFF"}}>數位媒體</Typography>
                    </Box>
                    <Typography variant="p" sx={{lineHeight:"1.5em"}}>口碑就在人群裡，<br/>80%的消費者購物前會上網搜尋網友的心得分享<br/>其中有44.7%的資訊來自於討論區</Typography>
                </Container>
            </Box>
            <Box className="indexSection">
                <Grid container spacing={0} sx={{width:"100%",my:"40px"}}>
                    <Grid xs={12} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center" order={{xs:1,md:2}}>
                        <img src="images/media/Media_01.jpg" alt="全方位數位金融服務" width="100%" />
                    </Grid>
                    <Grid xs={12} md={6} display="flex" justifyContent="flex-end" alignItems="center" order={{xs:2,md:1}}>
                        <Box sx={{maxWidth: {xs:"100%",md: "80%"}, textAlign: 'left', p:"5%"}}>
                            <Typography variant="body1" color="white">讓議題自然擴散</Typography>
                            <Typography gutterBottom variant="h4" color="white">論壇口碑行銷</Typography>
                            <Typography variant="body1" color="white">網路發達的時代，不僅是政治娛樂八卦等話題，民生相關的議題也離不開社群討論。搜尋討論區或網友心得分享，已成為消費者購買決策時的重要參考依據，然而，眾多社群媒體的屬性不同，經營方式也大不相同。決定產生議題討論前，先觀測市場風向、設計規劃好的內容，也必須思考各論壇的版區主題不同，規則不同，受眾也不同。聖洺行銷團隊熟知各論壇操作守則、擁有絕對敏感度，擅長口碑監控及論壇操作。在對的時間、運用對的議題幫客戶接觸對的人群，讓好的互動發生、把品牌與產品一口氣推到浪頭高點</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Container maxWidth="lg" sx={{ display: "flex", flexWrap: "wrap", textAlign: "left", justifyContent: "center", py: "40px" }}>
                <Box sx={{ width:"fit-content", mb:"20px", color: "#FFF", lineHeight: "2em" }}>
                    <Typography variant="body1">讓多人參與</Typography>
                    <Typography variant="h4" component="h2">口碑推文效應</Typography>
                </Box>
                <Box sx={{ width:"100%", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {
                        evaluateInfo.map((item, index)=>{
                            return(
                                <Card key={index} className='circleCard' sx={{borderRadius:"50%",mx:"-20px", background:"rgba(67,67,67,0.7)",aspectRatio: "1 / 1"}}>
                                    <CardContent sx={{px: 8, textAlign: "center"}}>
                                        <Typography gutterBottom variant="h5" sx={{color:"#ff853c"}}>{item.title}</Typography>
                                        <Typography variant="body2" color="text.secondary">{item.contentTxt}</Typography>
                                    </CardContent>
                                </Card>
                            )
                        })
                    }
                </Box>
            </Container>
            <Box className="indexSection">
                <Grid container spacing={0} sx={{width:"100%",my:"40px"}}>
                    <Grid xs={12} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center" order={{xs:1,md:2}}>
                        <img src="images/media/Media_02.png" alt="Life on LINE" width="100%" />
                    </Grid>
                    <Grid xs={12} md={6} display="flex" justifyContent="flex-end" alignItems="center" order={{xs:2,md:1}}>
                        <Box sx={{maxWidth: {xs:"100%",md: "80%"}, textAlign: 'left', p:"5%"}}>
                            <Typography variant="body1" color="white">Life on LINE</Typography>
                            <Typography gutterBottom variant="h4" color="white">LINE 粉絲 / 會員經營</Typography>
                            <Typography variant="body1" color="white">用LINE官方帳號建立「行動官網」導新客、留舊客、轉熟客，透過LINE通知型訊息、會員標籤管理，精準推播，提升顧客黏著度。</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="indexSection">
                <Grid container spacing={0} sx={{width:"100%",my:"40px"}}>
                    <Grid xs={12} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center">
                        <img src="images/media/Media_03.png" alt="Life on LINE" width="100%" />
                    </Grid>
                    <Grid xs={12} md={6} display="flex" justifyContent="flex-start" alignItems="center">
                        <Box sx={{width:{xs:"100%", md: "80%"}}}>
                            <Timeline
                                sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0.2,
                                    padding: "5%",
                                    },
                                }}
                            >
                                {
                                    TimeLineInfo.map((item, index)=>{
                                        return(
                                            <TimelineItem key={index}>
                                                <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Typography variant="h5" component="h2" sx={{fontWeight:"bold"}}>{item.title}</Typography>
                                                    <Typography variant="caption" component="p" sx={{color:"#ff853c"}}>{item.subTitle}</Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        )
                                    })
                                }
                            </Timeline>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="indexSection">
                <Grid container spacing={0} sx={{width:"100%",my:"40px"}}>
                    <Grid xs={12} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center" order={{xs:1,md:2}}>
                        <img src="images/media/Media_04.png" alt="打造品牌吸引力" width="100%" />
                    </Grid>
                    <Grid xs={12} md={6} display="flex" justifyContent="flex-end" alignItems="center" order={{xs:2,md:1}}>
                        <Box sx={{maxWidth: {xs:"100%",md: "80%"}, textAlign: 'left', p:"5%"}}>
                            <Typography variant="body1" color="white">打造品牌吸引力</Typography>
                            <Typography gutterBottom variant="h4" color="white">KOL 網紅口碑<br/>為您傳遞獨特價值</Typography>
                            <Typography variant="body1" color="white">依據您的品牌定位、產品特性、目標受眾、市場狀況…等綜合因素考量，精選出最適合您的消費族群做溝通的角色、身分與平台，我們掌握眾多KOL 類型，包括了其獨特的性格、職業、身分、文字風格、表達方式..等，為您推薦與規劃最適合的KOL來執行專案。</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="indexSection">
                <Grid container spacing={0} sx={{width:"100%",my:"40px"}}>
                    <Grid xs={12} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center" order={{xs:1,md:1}}>
                        <img src="images/media/Media_05.png" alt="打造品牌吸引力" width="100%" />
                    </Grid>
                    <Grid xs={12} md={6} display="flex" justifyContent="flex-start" alignItems="center" order={{xs:2,md:2}}>
                        <Box sx={{maxWidth: {xs:"100%",md: "80%"}, textAlign: 'left', p:"5%"}}>
                            <Typography variant="body1" color="white">社群媒體 KOL</Typography>
                            <Typography gutterBottom variant="h4" color="white">Facebook / Instagram人氣網紅<br/>口碑、影音、直播</Typography>
                            <Typography variant="body1" color="white">透過高人氣網紅帳號進行圖文分享、影音發佈、直播互動等口碑宣傳，將平台快速擴散、大量按讚留言與分享的特性完整發揮，創造社群口碑最大聲量。</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Container maxWidth="lg" sx={{ display: "flex", flexWrap: "wrap", textAlign: "left", justifyContent: "center", py: "40px" }}>
                <Box sx={{ width:"100%", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {
                        communityStep.map((item, index)=>{
                            return(
                                <Card key={index} className='PlanCard' sx={{background:"transparent"}}>
                                    <CardContent sx={{px: 8}}>
                                        <Typography variant="h2" sx={{fontWeight:"bold", borderBottom:"1px solid #FFF",pb:1}}>{`0${index+1}`}</Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{pt:2,pb:3}}>{item.SubTitle}</Typography>
                                        <Typography gutterBottom variant="h5">{item.title}</Typography>
                                        <Typography variant="body2" color="text.secondary">{item.contentTxt}</Typography>
                                    </CardContent>
                                </Card>
                            )
                        })
                    }
                </Box>
            </Container>
            <Box className="indexSection">
                <Grid container spacing={0} sx={{width:"100%",my:"40px"}}>
                    <Grid xs={12} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center" order={{xs:1,md:2}}>
                        <img src="images/media/Media_06.png" alt="Blog KOL" width="100%" />
                    </Grid>
                    <Grid xs={12} md={6} display="flex" justifyContent="flex-end" alignItems="center" order={{xs:2,md:1}}>
                        <Box sx={{maxWidth: {xs:"100%",md: "80%"}, textAlign: 'left', p:"5%"}}>
                            <Typography variant="body1" color="white">Blog KOL</Typography>
                            <Typography gutterBottom variant="h4" color="white">達人部落客</Typography>
                            <Typography variant="body1" color="white">雖然Facebook魅力席捲全台，但部落格仍因其豐富的資訊內容、利於搜尋結果優化、文章內容長期保留等特性，在口碑市場佔有無可動搖的地位和規模，更是許多廣告主執行口碑行銷的平台首選。</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Container maxWidth="lg" sx={{ display: "flex", flexWrap: "wrap", textAlign: "left", justifyContent: "center", py: "40px" }}>
                <Box sx={{ width:"100%", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {
                        KOLStep.map((item, index)=>{
                            return(
                                <Card key={index} className='PlanCard' sx={{background:"transparent"}}>
                                    <CardContent sx={{px: 8}}>
                                        <Typography variant="h2" sx={{fontWeight:"bold", borderBottom:"1px solid #FFF",pb:1}}>{`0${index+1}`}</Typography>
                                        <Typography gutterBottom variant="h5" sx={{pt:2}}>{item.title}</Typography>
                                        <Typography variant="body2" color="text.secondary">{item.contentTxt}</Typography>
                                    </CardContent>
                                </Card>
                            )
                        })
                    }
                </Box>
            </Container>
            <Box className="indexSection">
                <Grid container spacing={0} sx={{width:"100%",my:"40px"}}>
                    <Grid xs={12} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center" order={{xs:1,md:1}}>
                        <img src="images/media/Media_07.png" alt="社群媒體 KOL" width="100%" />
                    </Grid>
                    <Grid xs={12} md={6} display="flex" justifyContent="flex-start" alignItems="center" order={{xs:2,md:2}}>
                        <Box sx={{maxWidth: {xs:"100%",md: "80%"}, textAlign: 'left', p:"5%"}}>
                            <Typography variant="body1" color="white">社群媒體 KOL</Typography>
                            <Typography gutterBottom variant="h4" color="white">YOUTUBE影音口碑</Typography>
                            <Typography variant="body1" color="white">Youtube 頻道的影音風潮多年來持續穩定茁壯，Youtuber 創作者的強大魅力更是從歐美延燒至台灣，雖擴散度不及 Facebook 強大，但仍在影音市場佔有龍頭寶座。覺得圖文操作無法生動活潑的傳遞產品特性？試試 Youtuber 吧！</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Container maxWidth="lg" sx={{ display: "flex", flexWrap: "wrap", textAlign: "left", justifyContent: "center", py: "40px" }}>
                <Box sx={{ width:"100%", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {
                        ytStep.map((item, index)=>{
                            return(
                                <Card key={index} className='PlanCard' sx={{background:"transparent"}}>
                                    <CardContent sx={{px: 8}}>
                                        <Typography variant="h2" sx={{fontWeight:"bold", borderBottom:"1px solid #FFF",pb:1}}>{`0${index+1}`}</Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{pt:2,pb:3}}>{item.SubTitle}</Typography>
                                        <Typography gutterBottom variant="h5">{item.title}</Typography>
                                        <Typography variant="body2" color="text.secondary">{item.contentTxt}</Typography>
                                    </CardContent>
                                </Card>
                            )
                        })
                    }
                </Box>
            </Container>
        </>
    );
}

export default MediaPage;