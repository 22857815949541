import React, { createRef } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import HomePage from '../pages/home.jsx';
import MarketingPage from '../pages/marketing.jsx';
import FinancePage from '../pages/finance/finance.jsx';
import FinanceLineStore from '../pages/finance/finance_lineStore.jsx';
import FinanceLineOrder from '../pages/finance/finance_.lineOrder.jsx';
import FinanceEinv from '../pages/finance/finance_Einv.jsx';
import FinanceEPay from '../pages/finance/finance_EPay.jsx';
import MediaPage from '../pages/media.jsx';
import MyCasePage from '../pages/case.jsx';
import AboutPage from '../pages/about.jsx';
import ContactPage from '../pages/contact.jsx';
import NoMatch from '../pages/404.jsx';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

const routes = [
    { path: '/', name: 'Home', element: <HomePage />, nodeRef: createRef() },
    { path: '/marketing', name: 'marketing', element: <MarketingPage />, nodeRef: createRef() },
    { path: '/finance', name: 'finance', element: <FinancePage />, nodeRef: createRef() },
    { path: '/lineStore', name: 'lineStore', element: <FinanceLineStore />, nodeRef: createRef() },
    { path: '/lineOrder', name: 'lineOrder', element: <FinanceLineOrder />, nodeRef: createRef() },
    { path: '/einv', name: 'einv', element: <FinanceEinv />, nodeRef: createRef() },
    { path: '/epay', name: 'epay', element: <FinanceEPay />, nodeRef: createRef() },
    { path: '/media', name: 'media', element: <MediaPage />, nodeRef: createRef() },
    { path: '/case', name: 'case', element: <MyCasePage />, nodeRef: createRef() },
    { path: '/about', name: 'about', element: <AboutPage />, nodeRef: createRef() },
    { path: '/contact', name: 'contact', element: <ContactPage />, nodeRef: createRef() },
    { path: '*', name: 'noMatch', element: <NoMatch />, nodeRef: createRef() },
]

const AppRouters = () => {

    const location = useLocation()
    const { nodeRef } = routes.find((route) => route.path === location.pathname) ?? {}

    return (
        <>
            <SwitchTransition>
                <CSSTransition key={location.pathname} nodeRef={nodeRef} timeout={200} classNames="my-pages">
                    <div ref={nodeRef}>
                        <Routes>
                        {
                            routes.map((route) =>
                                /*route.path === '/' ? (
                                    <Route key={route.path} index element={route.element} />
                                ) : (
                                    <Route key={route.path} path={route.path} element={route.element} />
                                )*/
                                <Route key={route.path} path={route.path} element={route.element} />
                            )
                        }
                        </Routes>
                    </div>
                </CSSTransition>
            </SwitchTransition>
        </>
    )
}

export default AppRouters;