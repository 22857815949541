import PBModel from "./pbModel";

class Customer extends PBModel {
    get userId() {
        return this.getValue("Id")
    }
    get id() {
        return this.userId
    }
    get Name() {
        return this.getValue("Name")
    }
    get Points() {
        return this.getValue("Points")
    }
    get SessionID() {
        return this.getValue("SessionID")
    }
    get ExitSession() {
        return this.getValue("ExitSession")
    }
}

export default Customer;