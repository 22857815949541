
class PBModel {
    constructor(rawData) {

        const [getValue, setValue] = (() => {
            const cache = rawData;

            const getValue = (key) => {
                return cache[key]
            }

            const setValue = (key, value) => {
                cache[key] = value
                return this
            }

            return [getValue, setValue]
        })()

        this.getValue = getValue
        this.setValue = setValue
    }


    /* 取得原始id的Function */
    get id() {
        return this.getValue("id")
    }

    /* 修改id的Function */
    /* setId = (value) => {
        return this.setValue("id", value)
    } */
}

export default PBModel;