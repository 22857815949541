import React from 'react'
import {useNavigate} from 'react-router-dom';

import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";

const MarketingInfo = [
    {
        title: '改變傳統的行銷模式整合線上線下的銷售提供完整的經驗資源',
        subTitle: '創造品牌主 × 代理商 × 銷售通路三贏',
        contentTxt: '掌握主流趨勢，不斷的吸收與更新資訊，達到洞悉現今通路市場、同時具備獨特且無法取代的創意，讓我們在市場有前瞻性、加上專業知識與形象、有效運用各 種資源，建立與鞏固客戶品牌知名度，達到完美效益。',
        img: 'images/marketing/marketing_03.jpg',
        order1: { xs: 2, md: 1 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-start"
    }, {
        title: '深入了解目標消費者與消費者建立有價值的互動關係',
        subTitle: '提升「品牌」知名度',
        contentTxt: '透過策略性的市場活動，將品牌訊息有效傳達給消費者，以增加品牌知名度、提高消費者忠誠度、促進銷售增長等目的。',
        img: 'images/marketing/marketing_04.jpg',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end"
    },{
        title: '掌握數字、了解市場挖掘商機的關鍵',
        subTitle: '大數據時代',
        contentTxt: '透過統計和計算方法對大量數據進行挖掘和分析，以發現數據中隱藏的信息和模式。幫助企業更好地理解消費者需求和市場趨勢，制定更有針對性的策略和決策，提高業務效率和競爭力。',
        img: 'images/marketing/marketing_05.jpg',
        order1: { xs: 2, md: 1 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-start"
    }, {
        title: '創造獨特品牌視覺傳達品牌價值引領市場趨勢',
        subTitle: '創造品牌視覺形象',
        contentTxt: '設計視覺是品牌形象的重要元素之一，其目的在於透過各種視覺元素表現出品牌的特色、價值和風格，提升品牌的辨識度和認知度。在設計視覺中，色彩、字型、圖像等元素的運用需要與品牌形象相符合，以達到最佳的效果。',
        img: 'images/marketing/marketing_06.jpg',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end"
    },{
        title: '拓展通路、創造銷售契機提升品牌曝光',
        subTitle: '通路活動策略',
        contentTxt: '通路活動是以提升產品在市場銷售的策略為目標，透過舉辦各種活動，例如促銷、特價、樣品贈送、贈品活動等等，吸引消費者前往實體店面或網路購物平台購買產品，提升產品知名度、銷售量與市占率。',
        img: 'images/marketing/marketing_07.jpg',
        order1: { xs: 2, md: 1 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-start"
    }, {
        title: '獨特體驗 探索無限',
        subTitle: '展示體驗，創造精彩',
        contentTxt: '透過實體場域的體驗方式，讓消費者深入了解品牌核心價值與產品特點，建立品牌形象與消費者的情感聯結。著重於場地設計、展品佈置、演出設計等環節，以創造獨特的品牌氛圍與觀眾互動體驗，加強品牌與消費者之間的情感連結。',
        img: 'images/marketing/marketing_08.jpg',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end"
    },{
        title: '拓展通路、創造銷售契機提升品牌曝光',
        subTitle: '提高銷售效率',
        contentTxt: '企業在銷售過程中所使用的各種資源和技術，如網站、銷售系統、客戶關係管理系統、推銷文案等，提高銷售效率、增加客戶轉換率和提高客戶滿意度。能夠幫助企業更好地了解客戶需求、制定有效的銷售策略和提高銷售成果。',
        img: 'images/marketing/marketing_09.jpg',
        order1: { xs: 2, md: 1 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-start"
    }, {
        title: '各式視角與權限',
        subTitle: 'Classification Levels',
        contentTxt: '因應企業各層級關注的重點不同，提供報表的分層總覽協助總部、督導、店長等單位，透過全國、區域、分店等不同視角，來獲取最切身相關的資訊內容。此外，報表的權限設定具高度彈性，企業可以自行開放或關閉任何權限，來輕鬆調整帳號所屬角色的觀看及使用權限。',
        img: 'images/marketing/marketing_10.jpg',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end"
    }
]

const FeaturesInfo = [
    {
        title: '線上線下資料彙整',
        subTitle: 'Online And Offline',
        contentTxt: '相較於CRM客戶管理系統僅純粹紀錄客戶基本資料與消費紀錄，CDP可彙整用戶橫跨各式平台及裝置的零散資料，從線下門市、會員點券、LINE、App到線上電商、社群經營、及各式第三方數據，讓您輕輕鬆鬆以一個平台掌握所有顧客。',
        img: 'images/marketing/marketing_11.jpg',
        order1: { xs: 2, md: 1 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-start"
    }, {
        title: '智慧BI報表',
        subTitle: 'Business Intelligence Reporting',
        contentTxt: '當CDP串接數據源，例如POS系統、CRM客戶管理系統等渠道，再將數據彙整後，透過各項維度設定，即可打造專屬於您的報表，面相涵蓋銷售、營運、會員、商品、財務、監測等6大類型，匯聚凝鍊近30種BI數據報表，滿足企業多種的視角需求。',
        img: 'images/marketing/marketing_12.jpg',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end"
    },{
        title: '顧客分群分眾',
        subTitle: 'Customer Segmentation',
        contentTxt: '彙整顧客行為與消費軌跡，透過AI智慧演算法將顧客貼上屬性標籤，即可確實區隔不同類型之客群，進而擬定適合的行銷策略，有效活化舊客及衝刺新客消費。',
        img: 'images/marketing/marketing_13.jpg',
        order1: { xs: 2, md: 1 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-start"
    }, {
        title: '行銷自動化',
        subTitle: 'Automotive Marketing',
        contentTxt: '內建多套自動化行銷劇本，供企業彈性編輯使用，應時勢需求亦可自訂劇本。劇本進行時，符合資格的會員或精準客群可從LINE、APP、E-Mail、SMS等地方收到自動化的推播，開啟每一個增加營收的可能。',
        img: 'images/marketing/marketing_14.jpg',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end"
    },{
        title: '360會員輪廓',
        subTitle: 'Customer 360',
        contentTxt: '洞察會員從門店到電商各個接觸點的消費行為，藉以分析會員活躍度、劃分會員等級及評估會員價值。透過消費旅程的追蹤與紀錄，讓企業可以藉由每次消費的時間、通路、金額與商品，來掌握會員行為與偏好。同時，會員的點數與儲值金等優惠也能整合在此一併管理。',
        img: 'images/marketing/marketing_15.png',
        order1: { xs: 2, md: 1 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-start"
    }
]

function MarketingPage() {

    const navigate = useNavigate();

    return (
        <>
            <Box className="mainBanner" sx={{ backgroundImage: 'url(./images/marketing/marketing_01.jpg)', justifyContent:"flex-start" }}>
                <Container maxWidth="md" sx={{ textAlign: "left" }}>
                    <Box sx={{ width:"fit-content", mb:"20px" }}>
                        <Typography variant="h6">Integrated Marketing</Typography>
                        <Typography variant="h4" component="h1" sx={{mt:"10px", pb: "20px", borderBottom: "2px solid #FFF"}}>整合行銷</Typography>
                    </Box>
                    <Typography variant="p" sx={{lineHeight:"1.5em"}}>以品牌核心理念為出發點<br/>結合傳播媒體的獨特性<br/>整合各方面的資源最大實質效益</Typography>
                </Container>
            </Box>
            <Box className="indexSection">
                <Grid className="marketing_Banner" container spacing={0} sx={{width:"100%", backgroundImage: 'url(images/marketing/marketing_02.jpg)'}}>
                    <Grid xs={11} md={6} display="flex" justifyContent="flex-end" alignItems="center">
                        <Box sx={{maxWidth: {xs:"80%",md: "70%"}, textAlign: 'left', p:"5%"}}>
                            <Typography variant="body1" color="text.secondary" sx={{mb:1}}>創造品牌吸引力</Typography>
                            <Typography gutterBottom variant="h4" color="white">整合數位銷售工具結合媒體獨特性為品牌創造更高價值</Typography>
                            <Typography variant="body1" color="white">掌握主流趨勢，不斷的吸收與更新資訊，達到洞悉現今通路市場、同時具備獨特且無法取代的創意，讓我們在市場有前瞻性、加上專業知識與形象、有效運用各種資源，建立與鞏固客戶品牌知名度，達到完美效益。</Typography>
                        </Box>
                    </Grid>
                    <Grid xs={11} md={6} sx={{py:3}} display="flex" justifyContent="flex-start" alignItems="center">
                        <img src="images/marketing/Digtal_Marketing_Pic.png" alt="Digtal Marketing" width="70%" />
                    </Grid>
                </Grid>
            </Box>
            {
                MarketingInfo.map((item, index)=>{
                    return (
                        <Box className="indexSection" key={index}>
                            <Grid container spacing={0} sx={{width:"100%"}}>
                                <Grid xs={11} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center" order={item.order1}>
                                    <img src={item.img} alt={item.title} width="100%" />
                                </Grid>
                                <Grid xs={11} md={6} display="flex" justifyContent={item.justifyContent} alignItems="center" order={item.order2}>
                                    <Box sx={{maxWidth: {xs:"80%",md: "70%"}, textAlign: 'left', p:"5%"}}>
                                        <Typography variant="body1" color="text.secondary" sx={{mb:1}}>{item.subTitle}</Typography>
                                        <Typography gutterBottom variant="h4" color="white">{item.title}</Typography>
                                        <Typography variant="body1" color="text.secondary">{item.contentTxt}</Typography>
                                        {
                                            item.btnUrl ? (<Button color="inherit" variant="outlined" sx={{width: "150px", mt: 3}} onClick={()=>{navigate(item.btnUrl)}}>了解更多</Button>) : null
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )
                })
            }
            {
                FeaturesInfo.map((item, index)=>{
                    return (
                        <Box className="indexSection" key={index}>
                            <Grid container spacing={0} sx={{width:"100%"}}>
                                <Grid xs={11} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center" order={item.order1}>
                                    <img src={item.img} alt={item.title} width="100%" />
                                </Grid>
                                <Grid xs={11} md={6} display="flex" justifyContent={item.justifyContent} alignItems="center" order={item.order2}>
                                    <Box sx={{maxWidth: {xs:"80%",md: "70%"}, textAlign: 'left', p:"5%"}}>
                                        <Typography variant="body1" color="#e46d21" sx={{mb:1, textDecoration: "underline"}}>{item.subTitle}</Typography>
                                        <Typography gutterBottom variant="h4" color="white">{item.title}</Typography>
                                        <Typography variant="body1" color="text.secondary">{item.contentTxt}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )
                })
            }

        </>
    );
}

export default MarketingPage;