import React from 'react'

import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const LineStoreInfo = [
    {
        title: '品牌行動官網',
        subTitle: '與消費者零距離，行動隨身攜帶',
        feature: ["多元接觸點，與消費者零距離","官網/手機掃碼/LINE官方帳號入口","專屬品牌圖文選單與社群介面","讓消費者可以更方便地了解品牌資訊及產品","雲端商店帶著走"],
        img: './images/finance/LineStore/LineStore_01.png',
        order1: { xs: 1, md: 1 },
        order2: { xs: 2, md: 2 },
        justifyContent: "flex-start"
    },{
        title: '社群商城購物車',
        subTitle: '24小時購物車，一站式訂單管理，用LINE拓展商機',
        feature: ["24小時行動商店","後台快速上架商品，提高銷售效率","訂單管理系統，一站式管理 ","與您的LINE官方帳號整合，拓展無限商機"],
        img: './images/finance/LineStore/LineStore_02.png',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end"
    },{
        title: '團購訂單訊息推播',
        subTitle: 'LINE訂單推播，即時精準，方便快速！',
        feature: ["LINE訊息為您的訂單","消費者付款、物流選擇⼀次到位","可針對不同的受眾推播商品","比傳統紙張DM、傳單更有效","主動出擊，成交快速又便利"],
        img: './images/finance/LineStore/LineStore_03.png',
        order1: { xs: 1, md: 1 },
        order2: { xs: 2, md: 2 },
        justifyContent: "flex-start"
    }
]

function FinanceLineStore() {
    return (
        <>
            <Box className="mainBanner" sx={{ backgroundImage: 'url(./images/finance/LineStore/mainBanner.jpg)', justifyContent:"flex-start" }}>
                <Container maxWidth="md" sx={{ textAlign: "left" }}>
                    <Box sx={{ width:"fit-content", mb:"20px" }}>
                        <Typography variant="h6">Financial Technology</Typography>
                        <Typography variant="h4" component="h1" sx={{mt:"10px", pb: "20px", borderBottom: "2px solid #FFF"}}>LINE好友社群商城</Typography>
                    </Box>
                    <Typography variant="p" sx={{lineHeight:"1.5em"}}>提供全方位的金流與支付整合服務<br/>創新科技應用、提升風險控管能力、優化使用者體驗</Typography>
                </Container>
            </Box>
            {
                LineStoreInfo.map((item, index)=>{
                    return (
                        <Box className="indexSection" key={index} sx={{background: item.bg === "blue" ? "#212437" : ""}}>
                            <Grid container spacing={0} sx={{width:"100%"}}>
                                <Grid xs={11} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center" order={item.order1}>
                                    <img src={item.img} alt={item.title} width="100%" />
                                </Grid>
                                <Grid xs={11} md={6} display="flex" justifyContent={item.justifyContent} alignItems="center" order={item.order2}>
                                    <Box sx={{maxWidth: {xs:"100%",md: "70%"}, textAlign: 'left', p:"5%"}}>
                                    <Typography variant="body1" color="white" sx={{mb:1, color: "#cd6f00"}}>{item.subTitle}</Typography>
                                        <Typography gutterBottom variant="h4" color="white">{item.title}</Typography>
                                        <List
                                            sx={{ width: '100%' }}
                                            component="div"
                                            aria-labelledby="nested-list-subheader"
                                        >
                                            {
                                                item.feature.map((list, index)=>{
                                                    return(
                                                        <ListItem key={index}>
                                                            <CheckCircleIcon sx={{color: "#cd6f00"}} />
                                                            <Typography variant="body1" sx={{ml:1}} color="white">{list}</Typography>
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )
                })
            }
        </>
    );
}

export default FinanceLineStore;