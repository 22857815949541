import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import ContactService from '../service/contactService';

import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';

const contactService = new ContactService();

function ContactPage() {

    const { register, handleSubmit, formState:{ errors } } = useForm();
    const onSubmit = data => console.log(data);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [selectValues, setSelectValues] = useState({
        company: "",
        companyUrl: "",
        industry: "",
        ProductName: "",
        contactPerson: "",
        jobTitle: "",
        phone: "",
        email:"",
        service: "",
        pipeline: "",
        serviceType: "",
        detailMsg: ""
    });
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertMsg, setAlertMsg] = useState('')
    const [alertStyle, setAlertStyle] = useState({
        severity: 'success',
        bgColor: '',
        fontColor: ''
    })

    const selectHandleChange = (event) => {
        const { name, value } = event.target;

        setSelectValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    const submitForm = async(e) => {

        //if (!executeRecaptcha) {
        //    console.log('reCAPTCHA is not ready');
        //    return;
        //}
        //const token = await executeRecaptcha('saintmenWab');

        //const checkRecaptcha = await contactService.CheckreCAPTCHA(token)
        //console.log('reCAPTCHA:', checkRecaptcha);

        const sendData = await contactService.PostEmail(selectValues)
        //console.log(sendData);
        if (sendData === "successfully") {
            setAlertStyle({severity: 'success', bgColor:'#e0ffcc', fontColor: '#FFF'})
            setAlertMsg('信件已成功寄出，我們將盡快派員與您聯繫')
            setAlertOpen(true)
            setSelectValues({
                company: "",
                companyUrl: "",
                industry: "",
                ProductName: "",
                contactPerson: "",
                jobTitle: "",
                phone: "",
                email:"",
                service: "",
                pipeline: "",
                serviceType: "",
                detailMsg: ""
            })
        } else {
            setAlertStyle({severity: 'error', bgColor:'#b6341a', fontColor: '#FFF'})
            setAlertMsg('信件未成功寄出，請稍後再試 或 連繫我們')
            setAlertOpen(true)
        }
    }

    useEffect(() => {
        if (alertOpen) {
            const timer = setTimeout(() => {
                setAlertOpen(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [alertOpen]);

    return (
        <>
            <Box className="mainBanner" sx={{ backgroundImage: 'url(./images/contact/mainBanner.jpg)', justifyContent:"flex-start" }}>
                <Container maxWidth="md" sx={{ textAlign: "left" }}>
                    <Box sx={{ width:"fit-content", mb:"20px" }}>
                        <Typography variant="h6">Contact Us</Typography>
                        <Typography variant="h4" component="h1" sx={{mt:"10px", pb: "20px", borderBottom: "2px solid #FFF"}}>聯絡我們</Typography>
                    </Box>
                    <Typography variant="p" sx={{lineHeight:"1.5em"}}>想了解更多聖洺通路顧問的服務或進一步合作<br/>請留下相關資訊，將有專業顧問與您聯繫。</Typography>
                </Container>
            </Box>
            <Container maxWidth="lg" sx={{ display: "flex", flexWrap: "wrap", textAlign: "center", justifyContent: "center", py: "40px" }}>
                <Box sx={{ width:"fit-content", mb:"20px", color: "#FFF", lineHeight: "2em" }}>
                    <Typography variant="h6" gutterBottom>為提供您迅速而確實的服務，請務必正確並完整填寫以下資訊。</Typography>
                    <Typography variant="body2">標記<span style={{color:"red"}}>*</span>表示該欄為必填欄位</Typography>
                </Box>
                {/*<Box sx={{ width:"100%", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>*/}
                <Container maxWidth="md" sx={{ display: "flex", flexWrap: "wrap", textAlign: "left", justifyContent: "center", py: "40px" }}>
                    <Box
                        component="form"
                        sx={{
                            width:"100%",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            '& .MuiFormControl-root': { m:1, width: {xs:"calc(100% - 10px)", md: "calc(100% - 48px)"} },
                            '& .MuiTextField-root:not(.MuiFormControl-fullWidth)': { m: 1, width: {xs:"calc(100% - 10px)", md: "calc(50% - 32px)"} },
                            '& .MuiInputBase-root': {background: "rgba(100,100,100,0.5)"},
                            '& .MuiFormLabel-asterisk': { color: 'red' }
                        }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <TextField
                            color='warning'
                            name="contactPerson"
                            value={selectValues.contactPerson}
                            label="聯絡人"
                            {...register("contactPerson", {
                                required: '請輸入聯絡人名稱'
                            })}
                            error={!!errors?.contactPerson}
                            helperText={!!errors?.contactPerson ? errors?.contactPerson.message : null}
                            onChange={selectHandleChange}
                            required
                        />
                        <TextField
                            color='warning'
                            name="phone"
                            value={selectValues.phone}
                            label="聯絡電話"
                            {...register("phone", {
                                required: '電話號碼格式錯誤'
                            })}
                            error={!!errors?.phone}
                            helperText={!!errors?.phone ? errors?.phone.message : null}
                            onChange={selectHandleChange}
                            required
                        />
                        <TextField
                            color='warning'
                            name="email"
                            value={selectValues.email}
                            type="email"
                            label="Email"
                            {...register("email", {
                                required: "請輸入Email",
                                pattern: {
                                    value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                                    message: "請輸入正確Email"
                                }
                            })}
                            error={!!errors?.email}
                            helperText={!!errors?.email ? errors?.email.message : null}
                            onChange={selectHandleChange}
                            required
                        />
                        <TextField
                            color='warning'
                            name="company"
                            value={selectValues.company}
                            label="公司名稱 / 門市名稱"
                            onChange={selectHandleChange}
                        />
                        <TextField
                            color='warning'
                            name="companyUrl"
                            value={selectValues.companyUrl}
                            label="公司網站或粉絲團網址"
                            onChange={selectHandleChange}
                        />
                        <TextField
                            color='warning'
                            name="industry"
                            value={selectValues.industry}
                            label="產業別"
                            onChange={selectHandleChange}
                        />
                        <TextField
                            color='warning'
                            name="ProductName"
                            value={selectValues.ProductName}
                            label="產品名稱"
                            onChange={selectHandleChange}
                        />
                        <TextField
                            color='warning'
                            name="jobTitle"
                            value={selectValues.jobTitle}
                            label="職稱"
                            onChange={selectHandleChange}
                        />
                        <TextField
                            color='warning'
                            name="pipeline"
                            value={selectValues.pipeline}
                            label="您從哪裡得知我們的服務"
                            onChange={selectHandleChange}
                        />
                        <TextField
                            color='warning'
                            name="service"
                            value={selectValues.service}
                            label="希望合作項目"
                            onChange={selectHandleChange}
                        />
                        <TextField
                            fullWidth
                            color='warning'
                            name="serviceType"
                            value={selectValues.serviceType}
                            label="希望我們為您解決什麼問題?"
                            {...register("serviceType", {
                                required: '請輸入您需要的服務'
                            })}
                            error={!!errors?.serviceType}
                            helperText={!!errors?.serviceType ? errors?.serviceType.message : null}
                            onChange={selectHandleChange}
                            required
                        />
                        <TextField
                            color='warning'
                            name="detailMsg"
                            value={selectValues.detailMsg}
                            label="詳細說明"
                            {...register("detailMsg", {
                                required: '請簡述您的需求'
                            })}
                            error={!!errors?.detailMsg}
                            helperText={!!errors?.detailMsg ? errors?.detailMsg.message : null}
                            rows={6}
                            sx={{width: {xs: "calc(100% - 10px) !important", md:"calc(100% - 48px) !important"}, m:1}}
                            multiline
                            onChange={selectHandleChange}
                            required
                        />
                        <Button
                            size="large"
                            color='warning'
                            variant="contained"
                            sx={{mt:3}}
                            onClick={handleSubmit(submitForm)}
                        >送出表單</Button>
                    </Box>
                    <Collapse in={alertOpen} timeout={500} sx={{width: "100%", mt: 3}}>
                        <Alert open={alertOpen} onClose={() => {setAlertOpen(false)}} severity={alertStyle.severity} color={alertStyle.severity} sx={{ width: '100%', bgcolor: alertStyle.bgColor, '& .MuiAlert-root': {color: alertStyle.fontColor} }} variant="filled">
                            {alertMsg}
                        </Alert>
                    </Collapse>
                </Container>
            </Container>
        </>
    );
}

export default ContactPage;