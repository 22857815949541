import React from 'react'
import {useNavigate} from 'react-router-dom';

import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const FinTechCardInfo = [
    {
        title: '代收轉付金流',
        contentList: ["多元支付通路整合","電子票證串接","信用卡收款服務","第三方代收付","LINE Pay","Apple Pay"]
    }, {
        title: '電子商務',
        contentList: ["企業網站","商城網站規劃開發","金流平台建構","訂單與帳務管理","各式帳單統計報表服務 "]
    }, {
        title: '收款工具',
        contentList: ["微商城賣場","微商店QR貼支付","快速刷卡連結","B2B、B2C收款連結","微信小程序商城"]
    }, {
        title: '加值服務',
        contentList: ["電子發票","ePOS機發票開立","PCIDSS認證環境","專案建置系統服務","跨境微信支付串接","跨境支付寶支付串接"]
    }
]

const FinTechStep = [
    {
        title: '支付工具如何選？',
        contentTxt: '行動支付、電子支付該如何選擇？費率？撥款週期？，平均每一位消費者就超過3種以上的支付方式；透過「數位支付」不限空間、場域、時間，隨時都可讓消費者支付付款。',
        img: 'images/finance/finance_02.jpg'
    }, {
        title: '「彈性」開立發票',
        contentTxt: '電子發票好處多，除了節省成本、管理便捷、響應綠色環保、更提高客戶滿意度；發票開立可依據您與客戶實際情況與需求保有最大「彈性」。',
        img: 'images/finance/finance_03.jpg'
    }, {
        title: '人工記帳耗時又耗工？',
        contentTxt: '透過數位化記帳管理減少失誤！每日營收一目瞭然，快速查閱收付款狀態，確實掌握營運狀況。',
        img: 'images/finance/finance_04.jpg'
    }
]

const LineShopStep = [
    {
        title: '會員管理',
        contentTxt: '會員資料庫、記錄、分析及應用訂單查詢、會員卡服務、深入會員體驗，掌握與追蹤每個會員，深度經營客戶關係。',
        img: 'images/finance/financeIcon_01.png'
    }, {
        title: '訂單管理',
        contentTxt: '打造會員資料庫、記錄、分析，透過標籤分眾應用，依據用戶特徵、購買商品等互動行為，導購再行銷。',
        img: 'images/finance/financeIcon_02.png'
    }, {
        title: '數位支付',
        contentTxt: '打造個性化銷售，無論是服務業、零售業、工商團體等其它產業，透過「多元銷售模組」讓收款更簡易、輕鬆。',
        img: 'images/finance/financeIcon_03.png'
    }, {
        title: 'LINE行銷',
        contentTxt: '整合社群建立「LINE行動官網」透過標籤分眾應用，依據用戶特徵、購買商品等互動行為，導購再行銷。',
        img: 'images/finance/financeIcon_04.png'
    }
]

const FinanceInfo = [
    {
        title: '電子發票',
        subTitle: '單一平台多元服務，協助您輕鬆導入電子發票支援國際電商​金流、物流輕鬆整合',
        contentTxt: '不用自建系統，不用安裝任何程式，更不用額外購買電腦設備，只需要打開手機連網，隨時隨地都能開立電子發票。',
        feature: ["免加購POS機","雲端Web電子發票系統","電子發票收銀機","發票字軌自動取號配號","服務方案完整教育訓練","不限發票開立張數","中獎主動通知消費者","支援會員載具、批次匯入、郵件寄送","支援批次列印"],
        img: 'images/finance/financeCategory_01.png',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end",
        contactUrl: "/contact",
        readMoreUrl: "/einv",
        bg: "blue"
    },{
        title: '數位支付',
        subTitle: '電商時代，輕鬆開賣​，保障購物新時代新體驗無須建立商品資料・ 3秒完成建立・10秒完成付款',
        contentTxt: '依照不同場域情境，可以由您指定金額，或由付款者自填金額，門市銷售、活動收款、大樓管理費、團購、直播Donate或協會會費⋯⋯等任何情境都適用。',
        feature: ["免下載APP、免註冊會員，加LINE即為會員","免裝實體卡機，手機即是刷卡機","付款方式不受限 ","多元支付「線上刷卡」「虛擬帳號」","可無限建立連結","支援會員資訊、訂單資訊匯出","支援會員管理標籤設定","支援交易支付訊息訂單","支援Apple Pay、Google Pay"],
        img: 'images/finance/financeCategory_02.png',
        order1: { xs: 2, md: 1 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-start",
        contactUrl: "/contact",
        readMoreUrl: "/epay",
        bg: ""
    },{
        title: 'LINE好友社群商城',
        subTitle: '打造您的「行動官網」，整合社群建立品牌微商城，專屬購物車！',
        contentTxt: '省去平台高抽成手續費，一頁式微商城，結合物流、發票等資訊，打造高轉換率的行動購物車。',
        feature: ["免下載APP、免註冊會員，加LINE即為會員","商品組合無上限","發票自動開立","省去拍賣平台、開店網站「高抽成」「高月租」","三段式物流運費輕鬆設","全天候24小時行動商店","成立訂單主動通知","支援隱形賣場","支援訂單匯出"],
        img: 'images/finance/financeCategory_03.png',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end",
        contactUrl: "/contact",
        readMoreUrl: "/lineStore",
        bg: "blue"
    },{
        title: 'LINE應用線上點單',
        subTitle: '擺脫傳統紙本點餐，客人想吃什麼加LINE點用LINE及一台出單機，輕鬆管理所有訂單',
        contentTxt: '輕鬆省下30%人力成本，快速累積會員、提高回購，擴大線上曝光，提升餐廳營收，輕鬆數位升級！',
        feature: ["免下載APP、免註冊會員，加LINE即為會員","多平台整合一個連結就搞定！","線上菜單隨時更新，節省紙本成本","行動點單，即時收單","促銷活動","快點機、POS機同步列印明細單據","同時接收網路與門市訂單","成立訂單主動通知","多元支付"],
        img: 'images/finance/financeCategory_04.png',
        order1: { xs: 2, md: 1 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-start",
        contactUrl: "/contact",
        readMoreUrl: "/lineOrder",
        bg: ""
    }
]

function FinancePage() {

    const navigate = useNavigate();

    return (
        <>
            <Box className="mainBanner" sx={{ backgroundImage: 'url(./images/finance/mainBanner.jpg)', justifyContent:"flex-start" }}>
                <Container maxWidth="md" sx={{ textAlign: "left" }}>
                    <Box sx={{ width:"fit-content", mb:"20px" }}>
                        <Typography variant="h6">Financial Technology</Typography>
                        <Typography variant="h4" component="h1" sx={{mt:"10px", pb: "20px", borderBottom: "2px solid #FFF"}}>數位金融</Typography>
                    </Box>
                    <Typography variant="p" sx={{lineHeight:"1.5em"}}>提供全方位的金流與支付整合服務<br/>創新科技應用、提升風險控管能力、優化使用者體驗</Typography>
                </Container>
            </Box>
            <Box className="indexSection">
                <Grid container spacing={0} sx={{width:"100%"}}>
                    <Grid xs={12} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center" order={{xs:2,md:1}}>
                        <img src="images/finance/finance_01.jpg" alt="全方位數位金融服務" width="100%" />
                    </Grid>
                    <Grid xs={12} md={6} display="flex" justifyContent="flex-start" alignItems="center" order={{xs:1,md:2}}>
                        <Box sx={{maxWidth: {xs:"100%",md: "90%"}, textAlign: 'left', p:"5%"}}>
                            <Typography gutterBottom variant="h4" sx={{color:"#cd6f00"}}>全方位數位金融服務</Typography>
                            <Typography variant="body1" color="white">專注於提供全方位的金流與支付整合服務，核心價值為發展創新科技應用、提升風險控管能力、優化使用者體驗，結合食、衣、住、行、育、樂等多元化生活領域，持續提供安全便捷的解決方案，為企業客戶打造客製化的系統平台。</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Container maxWidth="lg" sx={{ display: "flex", flexWrap: "wrap", textAlign: "center", justifyContent: "center", py: "60px" }}>
                <Box sx={{ width:"100%", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {
                        FinTechCardInfo.map((item, index)=>{
                            return(
                                <Card key={index} className='FinTechCard' sx={{width:"100%", display: "flex", justifyContent: "center"}}>
                                    <CardContent>
                                        <Typography variant="h5" sx={{my:2}}>{item.title}</Typography>
                                        <List
                                            sx={{ width: '100%' }}
                                            component="div"
                                            aria-labelledby="nested-list-subheader"
                                        >
                                            {
                                                item.contentList.map((list, index)=>{
                                                    return(
                                                        <ListItem key={index}>
                                                            <CheckCircleIcon sx={{color: "#cd6f00"}} />
                                                            <Typography variant="body1" sx={{ml:1}}>{list}</Typography>
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List>
                                    </CardContent>
                                </Card>
                            )
                        })
                    }
                </Box>
            </Container>
            <Container maxWidth="lg" sx={{ display: "flex", flexWrap: "wrap", textAlign: "center", justifyContent: "center", pb: "60px" }}>
                <Box sx={{ width:"fit-content", mb:5, color: "#FFF", lineHeight: "2em" }}>
                    <Typography variant="h4" component="h2">還在為財務收款苦惱</Typography>
                    <Typography variant="h4" component="h2">始終找不到稅務的解決辦法嗎？</Typography>
                </Box>
                <Box sx={{ width:"100%", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {
                        FinTechStep.map((item, index)=>{
                            return(
                                <Card key={index} className='PlanCard' sx={{aspectRatio: "auto"}}>
                                    <CardMedia
                                        sx={{ width: "100%", height: 300 }}
                                        image={item.img}
                                        title={item.title}
                                    />
                                    <CardContent sx={{px: 8}}>
                                        <Typography gutterBottom variant="h5">{item.title}</Typography>
                                        <Typography variant="body2" color="text.secondary">{item.contentTxt}</Typography>
                                    </CardContent>
                                </Card>
                            )
                        })
                    }
                </Box>
            </Container>
            <Container maxWidth="lg" sx={{ display: "flex", flexWrap: "wrap", textAlign: "center", justifyContent: "center", pb: "60px" }}>
                <Box sx={{ width:"fit-content", mb:5, color: "#FFF", lineHeight: "2em" }}>
                    <Typography variant="body1">LINE 不只是通訊軟體</Typography>
                    <Typography variant="h4" component="h2">打造零售通路全方位客戶體驗</Typography>
                    <Typography variant="body1">以「360 度零死角」經營會員，整合零售通路銷售、品牌行銷導購與一對一客服服務，放大「即時訊息溝通」的效益。</Typography>
                </Box>
                <Box sx={{ width:"100%", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {
                        LineShopStep.map((item, index)=>{
                            return(
                                <Card key={index} className='FinTechCard'>
                                    <CardMedia
                                        sx={{ width: "100%", height: 170, backgroundSize:"auto" }}
                                        image={item.img}
                                        title={item.title}
                                    />
                                    <CardContent sx={{px: 2}}>
                                        <Typography gutterBottom variant="h5" sx={{borderBottom:"1px solid #FFF", pb:1, mb:1}}>{item.title}</Typography>
                                        <Typography variant="body2" color="white">{item.contentTxt}</Typography>
                                    </CardContent>
                                </Card>
                            )
                        })
                    }
                </Box>
            </Container>
            {
                FinanceInfo.map((item, index)=>{
                    return (
                        <Box className="indexSection" key={index} sx={{background: item.bg === "blue" ? "#212437" : ""}}>
                            <Grid container spacing={0} sx={{width:"100%"}}>
                                <Grid xs={11} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center" order={item.order1}>
                                    <img src={item.img} alt={item.title} width="100%" />
                                </Grid>
                                <Grid xs={11} md={6} display="flex" justifyContent={item.justifyContent} alignItems="center" order={item.order2}>
                                    <Box sx={{maxWidth: {xs:"100%",md: "70%"}, textAlign: 'left', p:"5%"}}>
                                        <Typography gutterBottom variant="h4" color="white">{item.title}</Typography>
                                        <Typography variant="body1" color="white" sx={{mb:1}}>{item.subTitle}</Typography>
                                        <Typography variant="body1" color="text.secondary">{item.contentTxt}</Typography>
                                        <Grid display="flex" spacing={2} sx={{width:"100%", my:3}}>
                                            <Grid xs><Button color='inherit' variant="outlined" sx={{width:"calc(100% - 6px)", mx:"3px"}} onClick={()=>{navigate(item.contactUrl)}}>免費諮詢</Button></Grid>
                                            <Grid xs><Button color='inherit' variant="outlined" sx={{width:"calc(100% - 6px)", mx:"3px"}} onClick={()=>{navigate(item.readMoreUrl)}}>了解更多</Button></Grid>
                                        </Grid>
                                        <List
                                            sx={{ width: '100%' }}
                                            component="div"
                                            aria-labelledby="nested-list-subheader"
                                        >
                                            {
                                                item.feature.map((list, index)=>{
                                                    return(
                                                        <ListItem key={index}>
                                                            <CheckCircleIcon sx={{color: "#cd6f00"}} />
                                                            <Typography variant="body1" sx={{ml:1}} color="white">{list}</Typography>
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )
                })
            }
        </>
    );
}

export default FinancePage;