import React, { useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { Grid } from "@mui/material";
import Button from '@mui/material/Button';

const NoMatch = () => {

    let navigate = useNavigate();

    //useEffect(()=>{
    //    if (isLogin) {
    //        return navigate('/')
    //    } else {
    //        return navigate('/login')
    //    }
    //})

    return (
        <Grid container component="div" className='CoursesList' justifyContent="center">
            <div className="NoLogin">
                <img src="./images/common/404Pic.svg" width={600} height={400} alt="請先登入會員" className="pic404"/>
                <h3 className="Title">我們找不到這個頁面！</h3>
                <Button variant='contained' color='primary' onClick={()=>{navigate('/')}} size="large">返回首頁</Button>
            </div>
        </Grid>
    )
}

export default NoMatch;