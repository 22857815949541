import React from 'react'
import { NavLink, useLocation } from "react-router-dom";

import Button from '@mui/material/Button';

const navItems = [
    {name: "整合行銷", url:"/marketing"},
    {name: "數位金融", url:"/finance"},
    {name: "數位媒體", url:"/media"},
    {name: "精彩案例", url:"/case"},
    {name: "關於聖洺", url:"/about"},
    {name: "聯絡我們", url:"/contact"}
];

function NavItems() {

    const location = useLocation();
    const isActive = location.pathname

    return (
        <>
            {
                navItems.map((item,index) => (
                    <Button
                        component={NavLink}
                        to={item.url}
                        key={index}
                        sx={{ color: '#ff853c', fontSize: '16px', '&.active': {
                            borderBottom: isActive ? '2px solid #ff853c' : '2px solid transparent',
                            borderRadius: isActive ? '4px 4px 0 0' : '4px'
                        }, }}
                    >
                        {item.name}
                    </Button>
                ))
            }
        </>
    );
}

export default NavItems;