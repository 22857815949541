import React from 'react'

import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const CaseList = [
    {
        client: 'AMD',
        activityType: '發表會',
        contentTxt: '經銷商大會',
        img: 'images/case/case_01.jpg'
    }, {
        client: 'SEAGATE',
        activityType: '發表會',
        contentTxt: 'LaCie產品講座',
        img: 'images/case/case_02.jpg'
    }, {
        client: 'FPBN & BENQ',
        activityType: '發表會',
        contentTxt: '產品體驗會',
        img: 'images/case/case_03.jpg'
    }
]

function MyCasePage() {
    return (
        <>
            <Box className="mainBanner" sx={{ backgroundImage: 'url(./images/case/mainBanner.jpg)', justifyContent:"flex-start" }}>
                <Container maxWidth="md" sx={{ textAlign: "left" }}>
                    <Box sx={{ width:"fit-content", mb:"20px" }}>
                        <Typography variant="h6">Wonderful Case</Typography>
                        <Typography variant="h4" component="h1" sx={{mt:"10px", pb: "20px", borderBottom: "2px solid #FFF"}}>精彩案例</Typography>
                    </Box>
                    <Typography variant="p" sx={{lineHeight:"1.5em"}}>有效的行銷，是針對正確的顧客，建立正確的關係。<br/>—— 菲利浦。科特勒/現代行銷學之父</Typography>
                </Container>
            </Box>
            <Container maxWidth="lg" sx={{py: "40px" }}>
                <Grid container spacing={2} sx={{ flexGrow: 0 }}>
                    <Grid md={12} xs={12}>
                        <Typography variant="body2">您最值得信賴的行銷夥伴</Typography>
                        <Typography variant="h4" gutterBottom>合作實例分享</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ flexGrow: 1, my: 3 }}>
                    {
                        CaseList.map((item, index)=>{
                            return(
                                <Grid md={4} xs={6} key={index}>
                                    <Card>
                                        <CardMedia
                                            sx={{ width: "100%", height: 300 }}
                                            image={item.img}
                                            title={item.client}
                                        />
                                        <CardContent sx={{px: 1}}>
                                            <Typography variant="body2" color="text.secondary">{item.activityType}</Typography>
                                            <Typography gutterBottom variant="h5">{item.client}</Typography>
                                            <Typography variant="body2" color="text.secondary">{item.contentTxt}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
        </>
    );
}

export default MyCasePage;