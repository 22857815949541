import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import NavContext from './contexts/navContext';
import Nav from "./layout/nav";
import Footer from "./layout/footer"
import AppRouters from "./layout/router";
import GoToTop from './layout/goToTop';
import ScrollToTop from './layout/ScrollToTop';

import './styles/App.scss';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Noto Sans TC',
      '-apple-system',
      'Arial',
      'sans-serif',
    ].join(','),
    fontWeight: 'bold'
  }
});

function App() {

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LevoywqAAAAAKdA11ltzUiaa13_WgepzLRnuOQh">
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <NavContext.Provider value={[menuOpen, setMenuOpen]}>
          <BrowserRouter>
              <ScrollToTop />
              <Nav />
              <main className="mdc-top-app-bar--fixed-adjust">
                <div className="App">
                    <AppRouters />
                </div>
                <GoToTop />
              </main>
              <Footer />
          </BrowserRouter>
        </NavContext.Provider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
