import React from 'react'

import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const EInvInfo = [
    {
        title: 'Web加值平台',
        subTitle: '不用自建系統，不用安裝任何程式',
        feature: ["Web平台開立，線上電商或實體門市皆可使用","不限發票開立張數","字軌配號更便利","營業稅申報快速便利免煩惱","支援熱感式、A4、A5多種電子發票格式列印"],
        img: './images/finance/EInv/EInv_01.png',
        order1: { xs: 1, md: 1 },
        order2: { xs: 2, md: 2 },
        justifyContent: "flex-start"
    },{
        title: 'ePOS電子發票系統',
        subTitle: '單一平台多元服務',
        feature: ["專屬POS介面，開立快速方便","支援Window系統，安裝於電腦就能使用","可離線開立發票，開立發票方便快速","支援連動印表機，列印電子發票證明聯", "支援載具、愛心碼、批次匯入開立等功能"],
        img: './images/finance/EInv/EInv_02.png',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end"
    },{
        title: '收銀機，效率方便',
        subTitle: '連接有線網路使用，即時將發票上傳至加值中心',
        feature: ["實體收銀機操作，商家無痛升級","無須PC，可直接電子發票開立、作廢","提供每日結帳報表","支援載具、愛心碼等功能","可離線開立電子發票"],
        img: './images/finance/EInv/EInv_03.png',
        order1: { xs: 1, md: 1 },
        order2: { xs: 2, md: 2 },
        justifyContent: "flex-start"
    },{
        title: '會員載具',
        subTitle: '購物快速、輕鬆保存',
        feature: ["會員載具平台","電子郵件寄送發票","雲端儲存電子發票","消費者可線上查詢發票明細"],
        img: './images/finance/EInv/EInv_04.png',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end"
    }
]

function FinanceEinv() {
    return (
        <>
            <Box className="mainBanner" sx={{ backgroundImage: 'url(./images/finance/EInv/mainBanner.jpg)', justifyContent:"flex-start" }}>
                <Container maxWidth="md" sx={{ textAlign: "left" }}>
                    <Box sx={{ width:"fit-content", mb:"20px" }}>
                        <Typography variant="h4" component="h1" sx={{mt:"10px", mb: "10px", pb: "10px", borderBottom: "2px solid #FFF"}}>電子發票逗陣e起來</Typography>
                        <Typography variant="h6">輕鬆結帳與開立 申辦到好免萬元</Typography>
                        <Typography variant="h6">不限張數吃到飽 線上線下皆可用</Typography>
                    </Box>
                    <Typography variant="h6" sx={{lineHeight:"1.5em"}}>
                        月費最低 <Typography variant='h5' component="span" sx={{fontWeight:"bold", color:"#000"}}>$300</Typography> 每年最低 <Typography variant='h5' component="span" sx={{fontWeight:"bold", color:"#000"}}>$3,600</Typography>
                    </Typography>
                </Container>
            </Box>
            {
                EInvInfo.map((item, index)=>{
                    return (
                        <Box className="indexSection" key={index} sx={{background: item.bg === "blue" ? "#212437" : ""}}>
                            <Grid container spacing={0} sx={{width:"100%"}}>
                                <Grid xs={11} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center" order={item.order1}>
                                    <img src={item.img} alt={item.title} width="100%" />
                                </Grid>
                                <Grid xs={11} md={6} display="flex" justifyContent={item.justifyContent} alignItems="center" order={item.order2}>
                                    <Box sx={{maxWidth: {xs:"100%",md: "70%"}, textAlign: 'left', p:"5%"}}>
                                    <Typography variant="body1" color="white" sx={{mb:1, color: "#cd6f00"}}>{item.subTitle}</Typography>
                                        <Typography gutterBottom variant="h4" color="white">{item.title}</Typography>
                                        <List
                                            sx={{ width: '100%' }}
                                            component="div"
                                            aria-labelledby="nested-list-subheader"
                                        >
                                            {
                                                item.feature.map((list, index)=>{
                                                    return(
                                                        <ListItem key={index}>
                                                            <CheckCircleIcon sx={{color: "#cd6f00"}} />
                                                            <Typography variant="body1" sx={{ml:1}} color="white">{list}</Typography>
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )
                })
            }
        </>
    );
}

export default FinanceEinv;