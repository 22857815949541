import React from 'react'

import NavItems from './navItem';

import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";


function Footer() {
    return (
        <>
            <Box className="FooterBOX" sx={{backgroundImage: "url(./images/common/FooterBG.jpg)", py:"50px"}}>
                <Container maxWidth="lg" sx={{ textAlign: "left" }}>
                    <Grid container spacing={0} sx={{width:"100%", borderBottom: "1px solid #f7b52c"}} justifyContent={{xs:'center',md: 'space-between'}} alignItems="center">
                        <Grid display="flex" justifyContent={{xs:'center',md: 'flex-start'}} alignItems="center" sx={{py: 2, textAlign: {xs: 'center', md: 'left'}}}>
                            <img src="./images/common/SaintMen_LOGO_W.svg" alt="Saintmen" width="120px" />
                        </Grid>
                        <Grid display="flex" justifyContent="flex-end" alignItems="center" sx={{ display: { xs: 'none', md: 'block' } }}>
                            <NavItems />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} justifyContent={{xs:'center',md: 'flex-start'}} sx={{mt:5, textAlign: {xs: 'center', md: 'left'}}}>
                        <Typography sx={{color: "#FFF"}} variant="subtitle2" component="div">10491臺北市中山區民權東路二段42號6樓<br/>TEL.02-2391-1761<br/>service@saintmen.com</Typography>
                    </Grid>

                </Container>
            </Box>
        </>
     );
}

export default Footer;