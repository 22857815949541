import React from 'react'

import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const LineOrderInfo = [
    {
        title: '行動點單，即時收單',
        subTitle: '即時輕鬆點餐，滿足味蕾需求',
        feature: ["提供多元接觸點餐，LINE官方帳號、官網、手機掃碼等多種方式進行點單，提高消費者的點餐便利","即時收單，即時掌握訂單資訊","支援產品調味客製化選項","提供網路付款方式，增加消費者支付的便捷性"],
        img: './images/finance/LineOrder/LineOrder_01.png',
        order1: { xs: 1, md: 1 },
        order2: { xs: 2, md: 2 },
        justifyContent: "flex-start"
    },{
        title: '付款方式',
        subTitle: '多元支付，靈活選擇，便利到您身邊',
        feature: ["支援現金、線上支付、LINE Pay等付款","支援門市自取與外送選項","可設定門市營業時間與外送距離"],
        img: './images/finance/LineOrder/LineOrder_02.png',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end"
    },{
        title: '促銷活動',
        subTitle: '提升品牌曝光，創造更多商機',
        feature: ["經營品牌促銷活動","專屬品牌圖文選單與社群介面","品牌獨立視覺形象"],
        img: './images/finance/LineOrder/LineOrder_03.png',
        order1: { xs: 1, md: 1 },
        order2: { xs: 2, md: 2 },
        justifyContent: "flex-start"
    },{
        title: '多元入口',
        subTitle: '快速接單，提升效率',
        feature: ["直串POS系統，POS機即可接單作業","增加線上訂單，提升門市集單收益","快速接單，節省人力效率"],
        img: './images/finance/LineOrder/LineOrder_04.png',
        order1: { xs: 1, md: 2 },
        order2: { xs: 2, md: 1 },
        justifyContent: "flex-end"
    }
]

function FinanceLineOrder() {
    return (
        <>
            <Box className="mainBanner" sx={{ backgroundImage: 'url(./images/finance/LineOrder/mainBanner.jpg)', justifyContent:"flex-start" }}>
                <Container maxWidth="md" sx={{ textAlign: "left", color: "#000" }}>
                    <Box sx={{ width:"fit-content", mb:"20px" }}>
                        <Typography variant="h6">Financial Technology</Typography>
                        <Typography variant="h4" component="h1" sx={{mt:"10px", pb: "20px", borderBottom: "2px solid #000"}}>LINE應用線上點餐</Typography>
                    </Box>
                    <Typography variant="p" sx={{lineHeight:"1.5em"}}>提供全方位的金流與支付整合服務<br/>創新科技應用、提升風險控管能力、優化使用者體驗</Typography>
                </Container>
            </Box>
            {
                LineOrderInfo.map((item, index)=>{
                    return (
                        <Box className="indexSection" key={index} sx={{background: item.bg === "blue" ? "#212437" : ""}}>
                            <Grid container spacing={0} sx={{width:"100%"}}>
                                <Grid xs={11} md={6} sx={{p:0}} display="flex" justifyContent="center" alignItems="center" order={item.order1}>
                                    <img src={item.img} alt={item.title} width="100%" />
                                </Grid>
                                <Grid xs={11} md={6} display="flex" justifyContent={item.justifyContent} alignItems="center" order={item.order2}>
                                    <Box sx={{maxWidth: {xs:"100%",md: "70%"}, textAlign: 'left', p:"5%"}}>
                                    <Typography variant="body1" color="white" sx={{mb:1, color: "#cd6f00"}}>{item.subTitle}</Typography>
                                        <Typography gutterBottom variant="h4" color="white">{item.title}</Typography>
                                        <List
                                            sx={{ width: '100%' }}
                                            component="div"
                                            aria-labelledby="nested-list-subheader"
                                        >
                                            {
                                                item.feature.map((list, index)=>{
                                                    return(
                                                        <ListItem key={index}>
                                                            <CheckCircleIcon sx={{color: "#cd6f00"}} />
                                                            <Typography variant="body1" sx={{ml:1}} color="white">{list}</Typography>
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )
                })
            }
        </>
    );
}

export default FinanceLineOrder;