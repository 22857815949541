import axios from 'axios'
import Customer from '../models/customer'

const baseURL = process.env.REACT_APP_DB_URL
const CUSTOMER_KEY = "customer"

//const customerUrl = "/json/Customer.json"
//const orderUrl = `${baseURL}/api/GetOrder`

const LoginUrl = `${baseURL}/api/MemberLogin/Website`
const LogoutUrl = `${baseURL}/api/MemberLogOut`
const UserInfoUrl = `${baseURL}/api/GetStudent`
const GetLoginLogUrl = `${baseURL}/api/GetLoginLog`

class CustomerService {

    constructor() {

        this.customerStorage = localStorage.getItem(CUSTOMER_KEY)

        //console.log(this.customerStorage)
        //console.log(this.rememberStorage)
        //console.log(this.LoginStorage)

        if (this.customerStorage === null) {
            this.clearCustomerStorage()
        } else {
            this.customerStorage = JSON.parse(this.customerStorage)
        }

    }

    clearCustomerStorage = () => {
        this.customerStorage = {}
        this.saveToCustomerStorage()
    }

    saveToCustomerStorage = () => {
        localStorage.setItem(CUSTOMER_KEY, JSON.stringify(this.customerStorage))
    }

    setCustomerIdToStorage = (Email, customerId, sessionID, exitSession) => {
        this.customerStorage["Email"] = Email
        this.customerStorage["userId"] = customerId
        this.customerStorage["SessionID"] = sessionID
        this.customerStorage["ExitSession"] = exitSession
        this.saveToCustomerStorage()
    }

    setLoginStateToStorage = (Email) => {
        this.rememberStorage = {}
        this.rememberStorage["Email"] = Email
        this.saveToRememberStorage()
    }

    setShouldBackToHome = () => {
        this.customerStorage["setShouldBackToHome"] = true
        this.saveToCustomerStorage()
    }

    clearShouldBackToHome = () => {
        this.customerStorage["setShouldBackToHome"] = null
        this.saveToCustomerStorage()
    }

    get shouldBackToHome () {
        return !!this.customerStorage["setShouldBackToHome"]
    }

    get isLoggedIn (){
        return !!this.getCustomerIdFromCookie()
    }

    getCustomerIdFromCookie = () => {
        return this.customerStorage["userId"] && this.customerStorage["SessionID"]
    }

    logIn = async(data) => {
        const {Email} = data
        const requestsString = Object.entries(Object.assign({}, data)).map(([key, value]) => `${key}=${value}`).join('&');
        //console.log(requestsString)
        //const customer = new Customer({email: Email})
        //this.setCustomerIdToStorage(11, Email)
        //var bytes  = CryptoJS.AES.decrypt(Password, '加密密碼');
        //var originalObj = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return await axios.post(LoginUrl, requestsString)
        .then((response) => {
            if (response.data.status === 'SUCCESS') {
                const customer = new Customer({...response.data.result})
                const { userId, SessionID, ExitSession } = customer
                this.setCustomerIdToStorage(Email, userId, SessionID, ExitSession)
                this.setLoginTime()
                return customer
            } else if (response.data.status === 'WrongPwd') {
                return '帳號或密碼錯誤'
            } else {
                return response.data.message
            }
        })
        .catch((error) => {
            console.log(error);
            return null
        });
    }

    logOut = async (deviceInfo, name) => {
        const { Email, SessionID } = this.customerStorage
        //const requestsString = Object.entries(Object.assign({}, sessionData)).map(([key, value]) => `${key}=${value}`).join('&');

        return await axios.post(LogoutUrl, `Email=${Email}&TokenID=${SessionID}&UserName=${name}&Ip=${deviceInfo.Ip}&Platform=${deviceInfo.Platform}&Browser=${deviceInfo.Browser}&BrowserVersion=${deviceInfo.BrowserVersion}&Device=${deviceInfo.Device}`)
        .then((response) => {
            //console.log(response)
            //this.clearCustomerStorage()
            //this.clearLoginStorage()
            //window.location.replace("/")
            if (response.data.status === 'SUCCESS') {
                this.clearCustomerStorage()
                this.clearLoginStorage()
                window.location.replace("/")
            } else {
                return response.data.message
            }
        })
        .catch((error) => {
            console.log(error);
            return null
        });

    }

    getUserInfo = async () => {
        const {userId, SessionID} = this.customerStorage

        return await axios.post(UserInfoUrl, `UserId=${userId}&TokenID=${SessionID}`)
        .then((response) => {
            if (response.data.status === 'SUCCESS') {
                return response.data.result
            } else {
                return response.data.message
            }
        }).catch((error) => {
          console.log(error);
          return []
        });
    }

    getLoginLog = async () => {

        const {userId, SessionID} = this.customerStorage

        return await axios.post(GetLoginLogUrl, `UserId=${userId}&TokenID=${SessionID}`)
        .then((response) => {
            //console.log(response)
            if (response.data.status === 'SUCCESS') {
                return response.data.result.reverse()
            } else {
                console.log(response.data.message)
                return response.data.message
            }
        }).catch((error) => {
          console.log(error);
          return null
        });
    }

    getUserIP = async() => {
        return await axios.get('https://ipinfo.io/json')
        .then((response) => {
            const myIP = response.data.ip
            return myIP
        }).catch((error) => {
          console.log(error);
          return null
        });
    }
}

export default CustomerService;